@import url('https://fonts.googleapis.com/css2?family=Economica&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap');

.stretch {
  width: 100%;
  padding: 0!important;
}

.center {
  text-align: center;
}

.playfair {
  font-family: 'Playfair Display SC', serif;
}
.economica {
  font-family: 'Economica', sans-serif;
}