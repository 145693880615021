.main-page {
    width: 100%;
}

.tab-content {
    padding: 2em;
}

.section-header {
    padding: 8px;
    margin: 12px;
}

.exp-block {
    margin: 0 0 24px;
    padding: 8px;
}

.date-range {
    margin-bottom: 4px;
}