@import url(https://fonts.googleapis.com/css2?family=Economica&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%!important;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  background-color: #222;
  min-height: 120vh;

}
.stretch {
  width: 100%;
  padding: 0!important;
}

.center {
  text-align: center;
}

.playfair {
  font-family: 'Playfair Display SC', serif;
}
.economica {
  font-family: 'Economica', sans-serif;
}
.main-page {
    width: 100%;
}

.tab-content {
    padding: 2em;
}

.section-header {
    padding: 8px;
    margin: 12px;
}

.exp-block {
    margin: 0 0 24px;
    padding: 8px;
}

.date-range {
    margin-bottom: 4px;
}
